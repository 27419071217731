import React, { useEffect } from "react";
import ReactGA from "react-ga";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-R7C8FV195Q");
    ReactGA.pageview("open-redirect-link");
    setTimeout(() => {
      window.location.href = "https://dreamkit.cc/shop/dkboombox";
    }, "300");
  }, []);

  return <div></div>;
};

export default App;
